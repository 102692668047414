<template>
  <v-row v-if="problem">
    <v-col cols="12" class="text-center display-3 my-5">
      <v-sheet color="transparent" class="banner">
        ({{ problem.a }})
        {{ problem.op.friendly }}
        ({{ problem.b }})
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["problem"],
};
</script>

<style scoped>
.tile {
  font-size: 3em;
  font-weight: bold;
  border: 3px solid #222;
  border-radius: 0.1em;
  text-align: center;
}
</style>