<template>
  <v-row dense v-if="isStart" class="justify-center align-center">
    <result-anim />

    <v-col cols="12" md="7">
      <v-card>
        <v-card-title>
          {{ authUser ? authUser.display_name : $lang("guest") }}
          <v-spacer></v-spacer>
          {{ $lang("score") }}: {{ score }}
        </v-card-title>
      </v-card>

      <problem-display :problem="problem" />
      <simple-timer
        class="mt-2"
        v-if="startTime"
        v-model="startTime"
        @done="onOutOfTime"
      />
      <v-progress-linear class="mt-2" rounded v-else color="red" height="15">
        {{ $lang("TIMESUP") }}
      </v-progress-linear>
    </v-col>

    <v-col cols="12" md="7">
      <NumPad @submitted="onGuessEnter" />
    </v-col>
    <v-col cols="12" md="7">
      <info-card
        :pointsCorrect="pointsCorrect"
        :pointsIncorrect="pointsIncorrect"
      />
    </v-col>
  </v-row>
  <v-row v-else column fill-height class="justify-center align-center">
    <v-col cols="12" md="6">
      <v-img src="/img/sign-practice.png" class="mb-4" />
      <v-btn
        :loading="loading"
        :disabled="loading"
        class="orange darken-3 anim-shine"
        block
        x-large
        @click="handleStart"
      >
        {{ $lang("start_game") }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import SimpleTimer from "./SimpleTimer";
import ResultAnim from "./ResultAnim";
import NumPad from "./NumPad";
import ProblemDisplay from "./ProblemDisplay";
import InfoCard from "./InfoCard.vue";
import { mapGetters } from "vuex";
import bus from "@/bus";

import { Device } from "@capacitor/device";
import { interstitial } from "@/plugins/admob/interstitial";

export default {
  components: {
    ResultAnim,
    SimpleTimer,
    ProblemDisplay,
    NumPad,
    InfoCard,
  },
  data() {
    return {
      problem: null,
      startTime: null,
      score: 0,
      timesup: false,
      animHolder: null,
      pointsCorrect: 3,
      pointsIncorrect: -1,
      isStart: false,
      loading: true,
      adStatus: null,
    };
  },
  watch: {
    adStatus(val) {
      this.loading = !(val == "showed" || "failed");
    },
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    sounds() {
      return {
        correct: this.getSound("/sounds/chime_1.mp3"),
        incorrect: this.getSound("/sounds/game-error.mp3"),
        lightBulbBreaking: this.getSound("/sounds/water_droplet_2.mp3"),
      };
    },
  },
  async mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
    this.info = await Device.getInfo();
    if (!this.adStatus != "showed") {
      if (this.info && this.info.platform != "web") {
        await interstitial(this);
      }
    }
  },
  methods: {
    handleStart() {
      this.isStart = true;
      this.newRound();
    },
    newRound() {
      this.startTime = null;
      this.timesup = false;
      this.problem = this.newProblem();
      setTimeout(() => {
        bus.$emit("playanim-stop");
        this.startTime = Date.now();
      }, 200);
    },
    onGuessEnter({ value, ctx }) {
      const ans = Number(value);
      if (ans === this.problem.answer) {
        ctx.answer = null;
        this.sounds.correct();
        bus.$emit("playanim-correct");
        if (!this.timesup) {
          this.score += this.pointsCorrect;
        }
        setTimeout(() => {
          this.newRound();
        }, 100);
      } else {
        this.sounds.incorrect();
        bus.$emit("playanim-incorrect");
        if (ans) {
          this.score += this.pointsIncorrect;
        }
      }
    },
    onOutOfTime() {
      this.sounds.lightBulbBreaking();
      this.timesup = true;
    },
    getSound(src) {
      const el = new Audio(src);
      return () => {
        el.currentTime = 0;
        el.play();
      };
    },
    newProblem() {
      const randNum = (ceil, floor = 1) =>
        floor + Math.trunc(Math.random() * (ceil - floor + 1));
      const newNum = () => randNum(10, -10);
      const ops = ["++", "--", "×*", "÷/"].map((s) => ({
        math: s[1],
        friendly: s[0],
      }));
      const ret = {
        a: newNum(),
        b: newNum(),
        op: ops[randNum(ops.length) - 1],
      };
      if (ret.op === ops[3]) {
        while (ret.b === 0) ret.b = newNum();
        ret.a = ret.b * ret.a;
      }
      ret.answer = eval(`${ret.a} ${ret.op.math} ${ret.b}`);
      return ret;
    },
  },
};
</script>
<style scoped>
.iinput {
  box-shadow: inset 0px 0px 10px #ccc;
  border-radius: 0.1em;
  font-size: 3em;
  text-align: center;
  background: #efefef;
  width: 100%;
}
.iinput::-webkit-inner-spin-button,
.iinput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
}
</style>