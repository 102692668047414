<template>
  <v-progress-linear
    v-if="power > 0"
    v-model="power"
    color="orange"
    height="15"
    rounded
  ></v-progress-linear>
</template>

<script>
export default {
  data() {
    return {
      power: 0,
    };
  },
  methods: {},
  mounted() {
    const interval = setInterval(() => {
      this.$forceUpdate();
      if (this.power === 100) {
        this.$emit("done");
        this.$emit("input", null);
        clearInterval(interval);
        this.power = 0;
      } else {
        this.power = this.power + 2;
      }
    }, 200);
  },
};
</script>