<template>
  <v-card class="pa-2">
    <v-card-text class="d-flex ma-0 pa-0">
      {{ $lang("Correct_Answer") }}
      <v-spacer></v-spacer>
      {{ "+" + pointsCorrect + $lang("points") }}
    </v-card-text>
    <v-card-text class="d-flex ma-0 pa-0">
      {{ $lang("Wrong_Answer") }}
      <v-spacer></v-spacer>
      {{ pointsIncorrect + $lang("points") }}
    </v-card-text>
    <v-card-text class="d-flex ma-0 pa-0">
      {{ $lang("Correct Answer After Times up") }}
      <v-spacer></v-spacer>
      {{ "0" + $lang("points") }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["pointsCorrect", "pointsIncorrect"],
};
</script>

<style>
</style>