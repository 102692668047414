<template>
  <div>
    <v-sheet class="answer-tile mb-3">
      <span v-if="answer">{{ answer }}</span>
      <span v-else style="color: #bbb">??</span>
    </v-sheet>
    <v-row dense class="">
      <v-col cols="2" v-for="i in digits" :key="i">
        <v-sheet
          v-ripple="{ center: true }"
          @click="handleClick(i)"
          class="ripple tile"
        >
          {{ i }}
        </v-sheet>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-sheet
          v-ripple="{ center: true }"
          @click="handleClick('backspace')"
          class="ripple tile"
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-sheet>
      </v-col>
      <v-col cols="6">
        <v-sheet
          v-ripple="{ center: true }"
          @click="handleClick('submit')"
          class="ripple tile green darken-4 anim-shine"
        >
          <v-icon dark>mdi-keyboard-return</v-icon>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["correctAnswer"],
  data() {
    return {
      answer: null,
      digits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "-", "+"],
      previousAnswer: null,
    };
  },
  computed: {
    canSubmit() {
      return !(this.previousAnswer === this.answer);
    },
  },
  methods: {
    handleSubmit(val) {
      const value = Number(val);
      if (!this.canSubmit || !val) {
        return;
      }
      this.$emit("submitted", { value, ctx: this });
      if (this.correctAnswer == value) {
        this.answer = null;
      }
      this.previousAnswer = this.answer;
    },
    playSound(src) {
      const el = new Audio(src);
      return () => {
        el.currentTime = 0;
        el.play();
      };
    },
    handleClick(e) {
      switch (e) {
        case "submit":
          this.handleSubmit(this.answer);
          break;
        case "backspace":
          this.answer = this.answer.slice(0, -1);
          break;
        case "-":
          if (!this.answer) {
            this.answer = "-";
          } else if (this.answer.substring(0, 1) === "-") {
            this.answer = this.answer.slice(1);
          } else {
            this.answer = "-" + this.answer;
          }
          break;
        case "+":
          if (this.answer.substring(0, 1) === "-") {
            this.answer = this.answer.slice(1);
          }
          break;
        default:
          this.answer = this.answer + e + "";
          break;
      }
      if (e == "submit") {
        this.playSound("/sounds/button-submit.mp3")();
      } else if (e == "backspace") {
        this.playSound("/sounds/snap.mp3")();
      } else {
        this.playSound("/sounds/button.mp3")();
      }
    },
  },
};
</script>

<style scoped>
.tile {
  font-size: 2em;
  font-weight: bold;
  border-radius: 0.1em;
  text-align: center;
}
.answer-tile {
  height: 1.5em;
  font-size: 3em;
  font-weight: bold;
  border-radius: 0.1em;
  text-align: center;
  background-color: #eee;
  color: #111;
}
</style>