<template>
  <lottie-animation
    style="
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 1000;
    "
    :path="path"
    :loop="false"
    :autoPlay="false"
    :speed="1"
    @AnimControl="setAnimController"
  />
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import bus from "@/bus";
export default {
  props: [""],
  components: {
    LottieAnimation,
  },
  data() {
    return {
      anim: {},
      animString: "",
    };
  },
  computed: {
    path() {
      switch (this.animString) {
        case "correct":
          return `./lottie/86756-thats-right.json`;
          break;

        case "incorrect":
          return `./lottie/86757-wrong.json`;
          break;

        default:
          return `./lottie/74694-confetti.json`;
          break;
      }
    },
  },
  watch: {},
  mounted() {
    bus.$on("playanim-correct", () => {
      console.log("playanim-correct");
      this.animString = "correct";
      setTimeout(() => {
        this.anim.play();
        this.anim.show();
        setTimeout(() => {
          this.anim.stop();
          this.anim.hide();
        }, 1500);
      }, 500);
    });
    bus.$on("playanim-incorrect", () => {
      console.log("playanim-incorrect");
      this.animString = "incorrect";
      setTimeout(() => {
        this.anim.play();
        this.anim.show();
        setTimeout(() => {
          this.anim.stop();
          this.anim.hide();
        }, 1500);
      }, 500);
    });
  },
  methods: {
    setAnimController(anim) {
      this.anim = anim;
      console.log("mounted", this.anim);
    },
  },
};
</script>

<style>
</style>